export default () => [
  // base propbar [do not delete]
  {
    from: new Date('03 February 2025 00:00:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
  {
    from: new Date('21 March 2025 00:00:00 UTC'),
    content: {
      text: '20% off selected Mother’s Day gifts. ',
      linkText: 'Shop now',
      link: '/list/shop-20-percent-off-selected-mothers-day?tag=tu:propbar:motherpromo',
      colour: 'black',
    },
  },
  {
    from: new Date('24 March 2025 00:00:00 UTC'),
    content: {
      text: '20% off selected Mother’s Day gifts & Earn 5x Nectar points. ',
      linkText: 'Shop now',
      link: '/list/shop-20-percent-off-selected-mothers-day?tag=tu:propbar:motherpromonectar',
      colour: 'black',
    },
  },
  {
    from: new Date('27 March 2025 00:00:00 UTC'),
    content: {
      text: 'Earn 5x Nectar points shopping with Tu online. ',
      linkText: 'Shop now',
      link: '/help/nectar?tag=tu:propbar:nectar5x',
      colour: 'purple',
    },
  },
  {
    from: new Date('11 April 2025 00:00:00 UTC'),
    content: {
      text: '20% off everything!',
      linkText: 'Shop now',
      link: '/list/shop-all-clothing?tag=tu:propbar:promo',
      colour: 'red',
    },
  },
  // base propbar
  {
    from: new Date('14 April 2025 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
]
